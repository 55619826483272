
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import Building from "@/components/reusable/policy-information/house-insurance/OwnedBuilding.vue";
import GoBackButton from "@/components/reusable/GoBackButton.vue";
import "viewerjs/dist/viewer.css";

export default defineComponent({
  name: "owned-building-house-insurance-policy-details",
  components: { GoBackButton, Building },
  data() {
    return {
      policyDetailsSummary: {},
      publicId: this.$route.params.publicId
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Owned", [
        "Policy",
        "Policy Information",
        "House Insurance",
        "Building"
      ]);
    });
  },
  created() {
    const router = useRouter();

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get the details of the vehicle in schdule list
    this.getOwnedBuildingDetails();

    //Set page title
    document.title =
      "Cooperative Admin: Owned Building House Insurance Policy Details | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getOwnedBuildingDetails() {
      const router = useRouter();
      //Get cart policyDetailsSummary details
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(
          `${variables.POLICIES_ROUTE}/folio-item/${this.publicId}`
        )
          .then(({ data }) => {
            //Assign data to corresponding fields
            this.policyDetailsSummary = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    }
  }
});
